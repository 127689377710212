<template>
<div class="my-certs">
  <div class="my-certs-top">
    <MyBreadcrumb></MyBreadcrumb>
  </div>
  <div class="cert-user-cont">
    <div class="cert-user-cont-top">
      <p>物料管理</p>

    </div>
    <div class="table">
      <Table border :columns="columns" :data="dataList" :loading="loading"></Table>
    </div>
    <div class="page">
      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
    </div>
  </div>
  <Modal
      v-model="receiveModal"
      title="领取成功！"
      :footer-hide="true"
  >
    <p style="font-size: 14px;margin-bottom: 20px">您的考试码为：{{ receiveInfo.data }}</p>
    <Button type="primary" @click="copy">复制考试码</Button>
  </Modal>
</div>
</template>

<script>
import util from '@/utils/tools.js';
// import FileSaver from "file-saver";
export default {
  name: "mycerts.vue",
  data(){
    return {
      page:1,
      pageSize:10,
      total:0,
      columns:[
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: '类型',
          render:(h,params)=>{
            return h('p',this.types[params.row.type])
          }
        },
        {
          title: '批次名称',
          key: 'import_name'
        },
        {
          title: '下发日期',
          render:(h,params)=>{
            return h('p',util.timeFormatter(
                new Date(+params.row.created_at * 1000),
                'yyyy-MM-dd hh:mm'
            ))
          }
        },
        {
          title: '有效期至',
          key:'expire_time',
          render:(h,params)=>{
            return h('p',util.timeFormatter(
                new Date(+params.row.expire_time * 1000),
                'yyyy-MM-dd hh:mm'
            ) || '-')
          }
        },
        {
          title: '操作',
          render: (h, params) => {
            return h('div', [
              params.row.type == 3 ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.is_expire == 1
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.showReceive();
                  }
                }
              }, params.row.is_expire == 1 ? '已过期' : params.row.expire_time > 0 ? '已领取，查看考试码' : '领取') : '',
              params.row.type != 3 ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.downLoad(this.curData.cert_pdf);
                  }
                }
              }, '下载PDF') : '',
              params.row.type != 3 ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {

                    this.curData = params.row;
                    this.downLoad(this.curData.cert_img);
                  }
                }
              }, '下载图片') :'',
            ]);
          }
        },
      ],
      dataList:[],
      statuses:{},
      types:{},
      loading:false,
      curData:{},
      receiveModal:false,
      receiveInfo:{},
    }
  },
  created(){
    this.getList();
  },
  methods:{
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
      }
      this.loading = true;
      this.api.certUser.datasetCertUserList(params).then((res)=>{

        this.dataList = res.list;

        this.statuses = res.statuses;

        this.types = res.types;

        this.total = Number(res.count);
        this.loading = false;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    downLoad(data){
      let uploadParams = {
        file:data
      };
      this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
        util.downLoad(res)
        this.receive();
      })
    },
    receive(fn){
      let params = {
        id:this.curData.id
      }
      this.api.certUser.datasetCertUserReceive(params).then((res)=>{
        fn && fn(res);
      })
    },
    copy(){
      this.$copyText(this.receiveInfo.data).then( (e)=>{
        this.$Message.success('复制成功');
      }, (e)=> {
        this.$Message.success('复制失败');
      })
    },
    showReceive(){
      this.receiveModal = true;
      this.receive((data)=>{
        this.receiveInfo = data.info;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.my-certs{
  .my-certs-top {
    margin: 20px;
  }
  .cert-user-cont{
    margin: 20px;
    padding: 20px;
    background-color: #FFFFFF;

    .cert-user-cont-top{
      height: 50px;
      border-bottom: 1px solid #EEEEEE;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >p{
        font-size: 16px;
        font-weight: bold;
      }

    }

    .table{
      margin-top: 20px;
    }
    .page{
      margin-top: 20px;
      text-align: right;
    }
  }
}
</style>
